import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

const NotFound = () => (
  <Layout>
    <h1>404 - Page Not Found</h1>
    <p>Unfortunately we couldn't find what you were looking for. "<a href="https://www.vilar.dev">Home</a> is where the heart is"!</p>
  </Layout>
)

export default NotFound
